//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { FocusTrap } from 'focus-trap-vue';

import FCInput from '@/shared/components/FCInput.vue';
import ButtonAction from '@/shared/components/ButtonAction.vue';
import ButtonLink from '@/shared/components/ButtonLink.vue';

Vue.component('FocusTrap', FocusTrap);
export default {
  name: 'JournalSurvey',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  components: {
    FCInput,
    ButtonAction,
    ButtonLink,
  },
  data: () => ({
    modalActive: true,
    email: '',
    emailError: false,
    emailErrorMsg: '',
    // eslint-disable-next-line
      regexEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
  }),
  methods: {
    validateEmail() {
      this.emailErrorMsg = '';
      if (this.email === '') {
        return;
      }
      this.emailError = !this.regexEmail.test(String(this.email).toLowerCase());
      if (this.emailError) {
        this.emailErrorMsg = 'Email format invalid.';
      }
    },

    clearErrors() {
      this.emailError = false;
      this.emailErrorMsg = '';
    },

    close() {
      this.$store.commit('SET_SHOW_EMAIL_INPUT', true);
      this.$emit('close');
    },

    journalEmail() {
      this.validateEmail();
      if (this.emailError) {
        return;
      }
      if (this.email === '') {
        this.emailError = true;
        this.emailErrorMsg = 'Please enter your email.';
        return;
      }

      this.$api_client.validateEmail(this.email.toLowerCase()).then((res) => {
        if (res.emailValid === false) {
          throw new Error('Invalid Email. Please check for errors or try with another email.');
        }
        this.$ecommAnalytics.identifyByEmail(this.email);
        this.$analytics.emailIdentified(this.checkoutEmail);

        this.$ecommAnalytics.SubscribeToJournalEmail();
        this.$analytics.campaignSubscribed({
          name: 'Journal Download',
          type: 'inline',
          email: this.email,
        });

        this.$store.commit('TOGGLE_SHOW_EMAIL_INPUT');
      })
        .catch((error) => {
          this.emailError = true;
          this.emailErrorMsg = error.message;
        });
    },
  },
  beforeDestroy() {
    this.modalActive = false;
  },
};
