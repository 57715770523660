

















import { defineComponent, PropType, ref } from '@vue/composition-api';
import { Carousel, Slide } from '@fc/angie-ui';
import PackageIncludedItemsSlide from './PackageIncludedItemsSlide.vue';

interface IItem {
  title: string;
  text: string;
}

interface ISlide {
  image: {
    filename: string;
    alt: string;
  };
  values: {
    title: string;
    subTitle: string;
    items: IItem[];
  };
}

export default defineComponent({
  name: 'PackageIncludedItemsCarousel',
  components: {
    PackageIncludedItemsSlide,
    Carousel,
    Slide,
  },
  props: {
    items: {
      type: Array as PropType<ISlide[]>,
      required: true,
    },
  },
  setup() {
    const arrowColor = ref('blue');
    function carouselArrowColor(color: 'blue' | 'dark-gray') {
      arrowColor.value = color;
    }

    return {
      carouselArrowColor,
      arrowColor,
    };
  },
});
