//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { blue } from '@fc/angie-ui/dist/styles/_js_exports.scss';
import IconBase from '~/components/icons/IconBase.vue';

const icons = {
  refund: {
    name: 'ThirtyDayRefund',
    width: '35',
    height: '35',
    text: '30-Day Refund Policy',
  },
  trainers: {
    name: 'Trainers',
    width: '35',
    height: '35',
    text: '30,000+ Members',
  },
  stats: {
    name: 'CheckoutTrackers',
    width: '35',
    height: '35',
    text: 'Real-Time Fitness Stats',
  },
};

export default {
  name: 'CartObjectionKillers',
  components: {
    IconBase,
  },
  computed: {
    icons() {
      return icons;
    },
    blue() {
      return blue;
    },
  },
};
