
















































import {
  defineComponent, computed, watch, toRef, PropType,
} from '@vue/composition-api';
import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import { FocusTrap } from 'focus-trap-vue';
import { useDrift } from '~/composables/useDrift';
import storeMutations from '~/store/constants/storeMutations';
import MobileDrawerSection from './MobileDrawerSection.vue';
import { INavigationTab } from './types';

Vue.component('FocusTrap', FocusTrap);
Vue.use(Vue2TouchEvents, {
  swipeTolerance: 100,
});

export default defineComponent({
  name: 'MobileDrawer',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    navigationTabData: {
      type: Array as PropType<INavigationTab[]>,
      default: () => [],
    },
    navigationSupportData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    MobileDrawerSection,
  },
  setup(props) {
    const mobilenavStyles = computed(() => (props.open ? {
      width: '100vw',
      height: '100vh',
    } : {
      width: '0',
      height: '0',
      padding: '0',
    }));

    function isLast(section: any) {
      return props.navigationTabData.indexOf(section) === props.navigationTabData.length - 1;
    }

    // eslint-disable-next-line no-undef
    const { $store } = useNuxtApp();
    const openRef = toRef(props, 'open');

    watch(openRef, (newVal) => {
      const { toggleDriftChat } = useDrift();
      toggleDriftChat(newVal);

      const body = document?.getElementsByTagName('BODY')[0] as HTMLElement;

      if (newVal) {
        body.style.overflow = 'hidden';
        $store.commit(storeMutations.SET_MODAL_SUPPRESSION, true);
      } else {
        body.style.overflow = 'scroll';
        $store.commit(storeMutations.SET_MODAL_SUPPRESSION, false);
      }
    });

    const getIndex = computed(() => (props.open ? '' : '-1'));

    return {
      mobilenavStyles,
      isLast,
      getIndex,
    };
  },
});
