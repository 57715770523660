
import { defineComponent } from '@vue/composition-api';
import { LinkButton } from '@fc/angie-ui';
import MediaBackground from '~/components/storyblok/MediaBackground/MediaBackground.vue';

export default defineComponent({
  name: 'ConsoleATF',
  components: {
    MediaBackground,
    LinkButton,
  },
  setup() {
    return {
      firstFrameImageUrl: 'https://a.storyblok.com/f/152150/2048x858/269aebbf3b/fc-antm-30-aug-first-frame-001.jpg',
      mp4Video: 'https://cdn-b.joinfightcamp.com/videos/fc-antm-30-oct-compressed-001.mp4',
      webmVideo: 'https://cdn-b.joinfightcamp.com/videos/fc-antm-30-oct-compressed-001.webm',
    };
  },
});
