




















































import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'CheckoutNavBar',
  props: {
    isConfirmation: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // eslint-disable-next-line no-undef
    const { $store } = useNuxtApp();
    const route = computed(() => $store.getters.currentRoute);
    function getLinkName(elName: string) {
      return `${route.value.name}_NavigationBar_${elName}`;
    }

    function triggerChat() {
      // eslint-disable-next-line no-undef
      openDriftChat();
    }

    const classTheme = computed(() => (props.isConfirmation ? 'confirmation-nav-bar' : 'checkout-nav-bar'));

    return {
      getLinkName, route, triggerChat, classTheme,
    };
  },
});
