import { render, staticRenderFns } from "./Journal_Survey.vue?vue&type=template&id=2440bca5&scoped=true&"
import script from "./Journal_Survey.vue?vue&type=script&lang=js&"
export * from "./Journal_Survey.vue?vue&type=script&lang=js&"
import style0 from "./Journal_Survey.vue?vue&type=style&index=0&id=2440bca5&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2440bca5",
  null
  
)

export default component.exports