import { render, staticRenderFns } from "./ConsoleATF.vue?vue&type=template&id=4ce27498&scoped=true&"
import script from "./ConsoleATF.vue?vue&type=script&lang=ts&"
export * from "./ConsoleATF.vue?vue&type=script&lang=ts&"
import style0 from "./ConsoleATF.vue?vue&type=style&index=0&id=4ce27498&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce27498",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediaBackground: require('/opt/build/repo/apps/shop/components/storyblok/MediaBackground/MediaBackground.vue').default,LinkButton: require('/opt/build/repo/apps/shop/components/storyblok/LinkButton/LinkButton.vue').default})
