//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { Actions } from '@/shared/store/actionsTypes';

export default {
  name: 'ShippingBlock',
  props: {
    title: {
      type: String,
      required: true,
    },
    showModalButton: {
      type: Boolean,
      default: false,
    },
    estDate: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
  },
  computed: {
    trackTitle() {
      return `${this.title}_InfoButton`;
    },
    getDateCopy() {
      return `Estimated Arrival Date: ${this.estDate}`;
    },
    ...mapGetters([
      'processingTotalCost',
    ]),
    isUSOrder() {
      return this.$store?.state?.checkout?.shipping?.country === 'US';
    },
  },
  methods: {
    openModal() {
      this.$store.dispatch(Actions.OPEN_MODAL, { component: '' });
    },
  },
};
