















import {
  defineComponent, PropType, computed, ref,
} from '@vue/composition-api';
import { IProduct } from '~/types/fightcamp';
import AccessoryCard from '~/components/marketplace/AccessoryCard.vue';
import { getProducts } from '~/utils/accessoryBundleProducts';
import { IStoryblokPage } from '~/types/storyblok';
import { useToggleDrawer } from '~/composables/useToggleDrawer';
import { transformShopifyProdId } from '~/selectors/selectors';

interface IRelatedProduct extends IProduct{
  includes: any[];
}

export default defineComponent({
  name: 'RelatedProducts',
  inheritAttrs: false,
  components: {
    AccessoryCard,
  },
  props: {
    products: {
      type: Array as PropType<IStoryblokPage[]>,
      required: true,
    },
  },
  setup(props) {
    // @ts-ignore
    const { $store } = useNuxtApp();
    const isShopifyOn = computed(() => $store.getters.isShopifyOn);
    const prods = ref([]);

    if (isShopifyOn.value) {
      prods.value = props.products.map((acc) => {
        const item = acc?.content?.body[0]?.container[0]?.contentContainer[0];
        const oldId = acc?.content?.body[0]?.container[0]?.contentContainer[0]?.productName;

        return {
          slug: acc.full_slug,
          ...transformShopifyProdId({
            testShopifyId: item?.testShopifyId, prodShopifyId: item?.prodShopifyId, oldId, store: $store,
          }),
        };
      });
    } else {
      const relatedProductsIds = props.products.map((product) => ({ id: product?.content?.body[0]?.container[0]?.contentContainer[0]?.productName, slug: product?.full_slug }));
      prods.value = getProducts(relatedProductsIds) as IRelatedProduct[];
    }

    const accessories = prods.value.filter((product) => !product?.includes); // filter out bundles

    const { openDrawer } = useToggleDrawer();

    return {
      accessories,
      openDrawer,
    };
  },
});
